import { LifeCycle, PageFeatureConfigSymbol, FeatureStateSymbol } from '@wix/thunderbolt-symbols'
import { initAnimations } from './screenInInit'
import { withDependencies, named, optional, ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { ScreenInFactory } from './types'
import { Animations } from 'feature-animations'
import { ScreenInManager } from './ScreenInManager/ScreenInManager'
import { name } from './symbols'

const screenInFactory: ScreenInFactory = (featureConfig, featureState, animationsProvider) => ({
	pageDidMount: async (pageId: string) => {
		const animationsManager = await animationsProvider.getInstance()
		const screenInManager = new ScreenInManager(animationsManager)
		const animations = initAnimations({
			model: featureConfig,
			manager: screenInManager,
			pageId,
			featureState,
		})
		animations.start()
	},
})

const ScreenIn = withDependencies(
	[named(PageFeatureConfigSymbol, name), named(FeatureStateSymbol, name), optional(Animations)],
	screenInFactory
)

export const page: ContainerModuleLoader = (bind) => {
	bind(LifeCycle.PageDidMountHandler).to(ScreenIn)
}
