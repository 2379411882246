import { Actions, ScreenInFeatureState } from './types'
import { ScreenInManager } from './ScreenInManager/ScreenInManager'
import screenIn from './screenIn'
import { IFeatureState } from 'thunderbolt-feature-state'

const initialState: ScreenInFeatureState = {
	definitions: {},
}

export function initAnimations({
	model,
	manager,
	pageId,
	featureState,
}: {
	model: Actions
	manager: ScreenInManager
	pageId: string
	featureState: IFeatureState<ScreenInFeatureState>
}) {
	const animationDefinitionsMap = featureState.get() || initialState
	const updatedAnimationDefinitionsMap = Object.assign(animationDefinitionsMap.definitions, model)
	featureState.update(() => ({ definitions: updatedAnimationDefinitionsMap }))
	manager.setDefinitions(updatedAnimationDefinitionsMap)
	manager.init(pageId)

	return screenIn({ model, manager })
}
